<script>

    export default {
        props: {
            termsAndConditionsData: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                showModal: false,
            };
        },
        methods: {
            closeModal() {
                this.showModal = false;
            },
            publishedStatus(status){
                switch (status) {
                    case 0:
                        return 'Draft';
                    case 1:
                        return 'Published';
                    case 2:
                        return 'Archived';
                    default:
                        return ''
                }
            }
        },
    };
</script>

<template>
    <b-modal v-model="showModal" id="view_terms_and_conditions" title="View Terms and Conditions" title-class="font-18" centered size="lg">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h6>Status: {{publishedStatus(termsAndConditionsData.status)}}</h6>
                <h6>Version: {{termsAndConditionsData.version}}</h6>
            </div>
            <div class="col-sm-12 text-center">
                <h4>{{termsAndConditionsData.title}}</h4>
            </div>
            <div class="col-sm-12 mt-3">
                <div style="max-height: 600px; overflow-y: auto;" v-html="termsAndConditionsData.content"></div>
            </div>
        </div>
        <template v-slot:modal-footer>
            <b-button variant="primary" @click="closeModal">Close</b-button>
        </template>
    </b-modal>
</template>
