<script>
    import General from "@/services/General";
    import CKEditor from "@ckeditor/ckeditor5-vue2";
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


    export default {
        props: {
            termsAndConditionsData: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                csrf_token: localStorage.getItem("csrf_token"),
                showModal: false,
                tryingToEdit: false,
                editor: ClassicEditor,
                editorConfig: {
                    // Add the allowedContent option to allow raw HTML content
                    allowedContent: true,
                },
                status_new: 0
            };
        },
        components: {
            ckeditor: CKEditor.component,
        },
        computed:{
            disabledStatus(){
                return this.termsAndConditionsData.status ? true : false
            },
            buttonLabel(){
                return this.disabledStatus ? 'Create new draft' : 'Change'
            }
        },
        methods: {

            refreshData() {
                this.$emit('onRefresh')
            },
            async editTermsAndConditions() {
                this.tryingToEdit = true;

                if(this.disabledStatus){

                    try {
                        General.createTermsAndConditions({
                            content: this.termsAndConditionsData.content,
                            tenant: this.termsAndConditionsData.tenant,
                            title: this.termsAndConditionsData.title,
                            status: 0,
                        }).then((response) => {
                            const res = response.data.data ? response.data.data : false;
                            const error = response.data.error ? response.data.error : '';
                            if (res) {
                                this.successmsg('Terms and Conditions Created!');
                                this.tryingToEdit = false;
                                this.closeModal();
                                this.refreshData();
                            } else {
                                this.failedmsg('Failed to create new Terms and Conditions!')
                            }
                        }).catch(error => {
                            const resData = error.response.data.error ? error.response.data.error : 'Failed';
                            const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                            this.failedmsg(errorMsg)
                        }).finally(() => {
                            this.tryingToEdit = false;
                        });
                    }catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error);
                    }
                }else{
                    try {
                        await General.editTermsAndConditions({
                            content: this.termsAndConditionsData.content,
                            version: this.termsAndConditionsData.version,
                            tenant: this.termsAndConditionsData.tenant,
                            status: this.status_new,
                        });
                        this.successmsg("Change has been saved");
                        this.closeModal();
                        this.refreshData();
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error);
                    } finally {
                        this.tryingToEdit = false;
                    }
                }
            },
            closeModal() {
                this.showModal = false;
            },
            modalShown(){
                this.status_new = this.termsAndConditionsData.status
            },
        },
    };
</script>

<template>
    <b-modal @shown="modalShown" v-model="showModal" id="edit_terms_and_conditions" title="Edit Terms and Conditions" title-class="font-18" centered size="lg">
        <form @submit.prevent="editTermsAndConditions">
            <div class="row">
                <div class="col-sm-3"><h5>Team: {{termsAndConditionsData.tenant}}</h5></div>
                <div class="col-sm-4">
                    <b-form-group>
                        <div class="form-check form-switch form-switch-lg">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="activeCheckbox"
                                v-model="status_new"
                                true-value="1"
                                false-value="0"
                                :disabled="disabledStatus"
                            >
                            <label class="form-check-label" for="activeCheckbox">Published</label>
                        </div>
                    </b-form-group>
                </div>
                <div class="col-sm-12 mb-2"><h5>Title: {{termsAndConditionsData.title}}</h5></div>
            </div>
        </form>

        <div style="max-height: 600px; overflow-y: auto;">
            <ckeditor v-model="termsAndConditionsData.content" :editor="editor" :config="editorConfig" />
        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editTermsAndConditions" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                {{buttonLabel}}
            </b-button>
        </template>
    </b-modal>
</template>
