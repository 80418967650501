<script>
    import Monitor from '@/services/Monitor';
    import General from '@/services/General';
    import validationMessages from '@/components/validations';
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import { required, maxLength } from "vuelidate/lib/validators";

    export default {
        components:{
            validationMessages, ckeditor: CKEditor.component
        },
        data() {
            return {
                showLoader: false,
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                image_removed: false,
                editor: ClassicEditor,
                tenants:'',
                tenant: '' ,
                title : '' ,
                tenants_options:[],
                Data: [],
                termsAndConditionsData: {
                    tenant: '',
                    content: '',
                },
                status : 0 ,
                csrf_token: localStorage.getItem('csrf_token'),
                editorConfig: {
                    // Add the allowedContent option to allow raw HTML content
                    allowedContent: true,
                },
            };
        },
        validations: {
            tenant: {
                required,
            },
            title: {
                required,
                maxLength: maxLength(100),
            }
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            async getTenants() {
                try {
                    this.showLoader = true
                    const response = await Monitor.getTenants();
                    this.tenants_options = response.data.tenants.map(tenant => ({
                        text: tenant,
                        value: tenant
                    }));
                } catch (error) {
                    console.log('Error fetching tenants:', error);
                }finally {
                    this.showLoader = false
                }
            },

            async getTermsAndConditions() {
                try {
                    const response = await General.getTermsAndConditions();
                    this.Data = response.data.data;
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.Data = []
                }
            },

            addTermsAndConditions() {
                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch();

                if (this.$v.$invalid || !this.tenant) {
                    this.tryingToSubmit = false;
                    return;
                } else {
                    // Call getTermsAndConditions to ensure Data is up-to-date
                    this.getTermsAndConditions()
                        .then(() => {
                            /*const currentDate = new Date().toISOString().split("T")[0];
                            const existingEntry = this.Data.find(
                                entry =>
                                entry.tenant === this.tenant &&
                                entry.version.split(" ")[0] === currentDate
                            );

                            if (existingEntry) {
                                this.failedmsg('Terms and Conditions already exist for the selected tenant and date.');
                                this.tryingToSubmit = false;
                                return;
                            }*/

                            const requestData = {
                                tenant: this.tenant,
                                content: this.termsAndConditionsData.content,
                                status: this.status,
                                title: this.title
                            };

                            General.createTermsAndConditions(requestData)
                                .then((response) => {
                                    const res = response.data.data ? response.data.data : false;
                                    const error = response.data.error ? response.data.error : '';
                                    if (res) {
                                        this.successmsg('Terms and Conditions Created!');
                                        this.refreshData();
                                        this.closeModal();
                                    } else {
                                        this.failedmsg('Failed to create new Terms and Conditions!')
                                    }
                                })
                                .catch(error => {
                                    const resData = error.response.data.error ? error.response.data.error : 'Failed';
                                    const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                                    this.failedmsg(errorMsg)
                                })
                                .finally(() => {
                                    this.tryingToSubmit = false;
                                });
                        })
                        .catch(error => {
                            console.error('Error fetching terms and conditions:', error);
                            this.tryingToSubmit = false;
                        });
                }
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.submitted = false
                this.tenant = ''
                this.title = ''
                this.status = ''
                this.termsAndConditionsData.content = ''
            },

            modalShown(){
                this.getTenants()
            }
        }
    };
</script>

<template>
    <b-modal @shown="modalShown" id="add_new_terms_and_conditions" v-model="showModal" @hidden="resetProps" title="Add New Terms and Conditions" title-class="font-18" size="lg">
        <form @submit.prevent="addTermsAndConditions" v-if="!showLoader">
            <div class="row">
                <div class="col-4">
                    <b-form-group label="Select Team" label-for="formrow-tenant-input" class="mb-3">
                        <b-form-select
                                v-model="tenant"
                                :options="tenants_options"
                                :class="{ 'is-invalid': submitted && $v.tenant.$error, }"
                        ></b-form-select>
                        <validationMessages v-if="submitted" :fieldName="'Tenants'" :validationName="$v.tenant"></validationMessages>
                    </b-form-group>
                </div>
                <div class="col-7">
                    <b-form-group class="mb-3">
                        <br>
                        <div class="form-check form-switch form-switch-lg mt-3 float-end">
                            <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="activeCheckbox"
                                    v-model="status"
                                    true-value="1"
                                    false-value="0"
                            >
                            <label class="form-check-label" for="activeCheckbox">Published</label>
                        </div>
                    </b-form-group>
                </div>
                <b-form-group label="Title" label-for="formrow-title-input" class="mb-3">
                    <b-form-input
                            v-model.trim="title"
                            type="text"
                            id="title"
                            aria-describedby="title-feedback"
                            :class="{ 'is-invalid': submitted && $v.title.$error, }"
                    >
                    </b-form-input>
                    <validationMessages v-if="submitted" :fieldName="'Title'" :validationName="$v.title"></validationMessages>
                </b-form-group>

                <div style="max-height: 600px; overflow-y: auto;">
                    <ckeditor v-model="termsAndConditionsData.content" :editor="editor" :config="editorConfig" />
                </div>
            </div>
        </form>
        <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                <div class="row">
                    <div class="col-12">
                        <div class="row  align-items-center justify-content-center">
                            <b-spinner large></b-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addTermsAndConditions" :disabled="tryingToSubmit || showLoader">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>
</template>
